import React, { useState, useEffect } from "react";
import { ref, get, set, onValue } from "firebase/database";
import { database } from "./firebase"; // Ensure this path is correct
import "./App.css";

function App() {
  const [visitorCount, setVisitorCount] = useState(0);
  const [recipient] = useState("madandilawar@gmail.com");
  const [ccRecipients] = useState([
    "speaker-rajassembly@nic.in",
    "drkirodi.meena51@sansad.nic.in",
    "cmrajasthan@nic.in",
    "csraj@rajasthan.gov.in",
    "info@patrika.com",
    "shishir.awasthi@firstindianews.com",
    "rajyavardhanofficial@gmail.com",
    "news@sachbedhadak.in",
    "info@jantv.in",
    "db.reporter@dainikbhaskar.com",
    "ps-itc@rajasthan.gov.in",
    "amitshah.mp@sansad.nic.in",
    "appt.pmo@nic.in",
    "mailwhatsappuniversity@gmail.com",
  ]);
  const [subject] = useState(
    "शिक्षक भर्ती लेवल 1 में 50 प्रतिशत महिला आरक्षण में पुनर्विचार करने के लिए"
  );
  const [body] = useState(
    `मान्यवर जी,
    
उपरोक्त विषय में निवेदन है कि माननीय मुख्यमंत्री जी ने शिक्षक भर्ती में महिला आरक्षण 30 प्रतिशत से बढ़ाकर 50 प्रतिशत करने का फैसला लिया है तथा पंचायत राज कानून में संशोधन करने की अनुमति भी दे दी है। यह सरकार गलत और लाखों पुरूषों के साथ बहुत बड़ा अन्याय है। इस फैसले से प्रदेश के लाखों युवा अक्रोश और डिप्रेशन में हैं। पिछले कई दिनों से सोशल मीडिया पर ट्विटर ट्रेंड, धरना प्रदर्शन और आंदोलन कर रहे हैं। यह फैसला निम्न कारणों से गलत और अन्यायपूर्ण है -

1- नर्सरी ट्रेनिंग टीचर भर्ती/पूर्व प्राथमिक शिक्षक भर्ती और बाल वटिका योजना में प्रारंभिक शिक्षिका भर्ती में महिलाओं को 30 प्रतिशत आरक्षण और शिक्षक भर्ती लेवल 1 में 50 प्रतिशत महिला आरक्षण देना साफ-साफ गलत और अन्याय है। जबकि बच्चों को मातृत्व की भावना अनिवार्य है और बाल वटिका भर्ती में जरूरत होती है। वहां पर महिलाओं को केवल 30 प्रतिशत आरक्षण दिया गया है।

2- पीएसटीसी परीक्षा जो बीएसटीसी कोर्स करने के लिए होती है उसमें महिलाओं को 30 प्रतिशत महिला आरक्षण दे रखा है इसलिए शैक्षिक कार्य में 50 प्रतिशत महिला आरक्षण देना गलत है क्योंकि इससे छात्रों के बीच प्रतिस्पर्धा को कम करता है। जिससे शिक्षा की गुणवत्ता के साथ संघर्ष होगा जो आरटीआई एक्ट 2009 और अनुच्छेद 21 का उल्लंघन है। जबकि आरटीआई एक्ट में फ्री एंड कम्पल्सरी एजुकेशन के साथ क्वालिटी एजुकेशन देने का भी प्रावधान है। शिक्षा की गुणवत्ता को लेकर सुप्रीम कोर्ट ने भी कई महत्वपूर्ण निर्णय दिए हैं।

3- वर्तमान में शिक्षा विभाग में कुल शिक्षक 2.40 लाख शिक्षक कार्यरत हैं, जिसमें से 1.05 लाख महिला शिक्षक कार्यरत हैं जो लगभग 44 प्रतिशत महिला है। दैनिक भास्कर रिपोर्ट के अनुसार जैसे देखा जाए तो दिन ब दिन उनका अनुपात अपने वर्तमान नियम के अनुसार बढ़ रहा है। जैसे जितनी महिलाएं बीएसटीसी कोर्स की हैं उनमें से 50 प्रतिशत से अधिक का चयन हो चुका है और हो रहा है।

4- शिक्षा विभाग भारत सरकार की 2021-22 रिपोर्ट के अनुसार कुल शिक्षक 9507123 हैं, जिसमें महिला 48.76 लाख और पुरूष 46.30 लाख हैं।

5- शिक्षा विभाग भारत सरकार की 2021-22 रिपोर्ट के अनुसार राजस्थान में कुल शिक्षक प्राइमरी में 211737 हैं, जिसमें पुरूष 113812 और महिला 97925 हैं। जो लगभग 47 प्रतिशत महिला है।

6- पंचायत राज कानून में हॉरिजॉन्टल महिला आरक्षण 50 प्रतिशत करने से कुल हॉरिजॉन्टल आरक्षण की 50 प्रतिशत सीमा का उल्लंघन होगा जो साफ तौर पर गैरकानूनी है तथा सुप्रीम कोर्ट के कानूनी पीठ के महत्वपूर्ण फैसलों का उल्लंघन होगा। क्योंकि महिला आरक्षण हॉरिजॉन्टल तरीके से दिया जाता है तथा हॉरिजॉन्टल आरक्षण अन्य भी हैं जैसे एक्स सर्विसमैन स्पोर्ट्स वगैरह। हॉरिजॉन्टल आरक्षण को वर्टिकल आरक्षण में केटेगरी वाइज लागू किया जाता है।
कुल हॉरिजॉन्टल आरक्षण इस प्रकार से होगा 50 प्रतिशत महिला, 12.5 प्रतिशत एक्स सर्विसमैन, 2 प्रतिशत स्पोर्ट्स, 4 प्रतिशत पीडब्ल्यूडी, कुल 68.5 प्रतिशत।
फेस युवा 90 प्रतिशत लड़कों के लिए 31.5 प्रतिशत बचा है जो अन्याय है। जब 30 प्रतिशत महिला आरक्षण में 40 प्रतिशत महिला का चयन हो रहा है तो अपेक्षित 50 प्रतिशत में 60 प्रतिशत से अधिक का चयन होगा तब पुरुषों के साथ बहुत बड़ा अन्याय होगा।

7- 50 प्रतिशत महिलाओं को आरक्षण देने से जब उनका अनुपात बढ़ेगा, तब शिक्षा विभाग के अन्य उच्च स्तर के शिक्षकों में धीरे-धीरे 100 प्रतिशत महिलाएं हो जाएंगी, जो स्वच्छ रूप से अन्याय है।

8- 8- BSTC कोर्स किए पुरुषों के पास नौकरी पाने का एक ही मौका है। क्योंकि ये सेकेंड ग्रेड, फर्स्ट ग्रेड, RS प्राइमरी शिक्षण जैसे पदों के लिए भी योग्य नहीं हैं। क्योंकि BSTC कोर्स कक्षा 12 के बाद किया जाता है। राजस्थान की अधिकांश वैकेंसी स्नातक स्तर और अन्य प्रोफेशनल कोर्स के साथ आती हैं। इसलिए ये योग्य नहीं हैं। जो ये 3 लाख  लड़के कोर्स कर चुकीं, उनका क्या होगा? उनका पैसा बर्बाद हो गया। लोन/ऋण लेकर कोर्स किया, वह कैसे चुकाएंगी?

9- आपने NCTE से सलाह लिए बगैर मातृत्व की भावना का आधार बनाकर 50 प्रतिशत महिलाओं के लिए सीट आरक्षित कर दी, जो गलत है क्योंकि BSTC कोर्स NCTE से मान्यता प्राप्त संस्था द्वारा किया जाता है और पाठ्यक्रम का निर्धारण भी NCTE ही करती है। NCTE के नियमों के अनुसार जिसने BSTC कोर्स पूरा कर लिया, वह कक्षा 1 से 5 तक पढ़ाने के लिए योग्य है। इसमें लिंग और मातृत्व की भावना के आधार पर भेदभाव नहीं कर सकते। पुरुष और महिलाएं दोनों में से मातृत्व की भावना से कौन पढ़ाने के लिए अधिक योग्य है, इस आधार पर NCTE कोर्स नहीं करती और न ही निर्धारित करती। NCTE के अनुसार दोनों बराबर प्रशिक्षित हैं। बच्चों को पढ़ाने के लिए मनोविज्ञान शिक्षा विधियां महत्वपूर्ण होती हैं, जो पुरुष और महिलाएं दोनों सीखते हैं।

10- बिना किसी लॉजिटिक्‍स एक्सपर्ट कमेटी के ओवरव्यू लिए महिलाओं का एनलिसिस जैसे कितनी महिलाओं का चयन हो रहा, कितनी महिलाएं BSTC कोर्स कर रही हैं, आदि के शिक्षक भर्ती में महिला आरक्षण 30 प्रतिशत से 50 प्रतिशत बढ़ाने का फैसला दे दिया जो गलत है। पिछले 7-8 वर्षों से महिलाओं का प्रतिशत लगातार बढ़ रहा है।

आपके इस फैसले के कारण अनुच्छेद 14, 15, 16 का उल्लंघन हो रहा है। अतः आपसे निवेदन है कि लाखों पुरुषों के हित को ध्यान में रखते हुए इस फैसले को वापस लेने की कृपा करें। अन्यथा मजबूर होकर बड़े स्तर पर आंदोलन भूख हड़ताल/विरोध करना पड़ेगा और न्यायालय की शरण में जाना पड़ेगा, जिसमें लाखों रुपए खर्च होंगे और भर्ती कोर्ट में वाद जाएगी।`
  );

  const displayedCCRecipients = ccRecipients.filter(
    (email) => email !== "mailwhatsappuniversity@gmail.com"
  );

  const handleSendEmail = (e) => {
    e.preventDefault();
    const ccString = ccRecipients.join(",");
    const mailtoLink = `mailto:${recipient}?cc=${encodeURIComponent(
      ccString
    )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    const isVisitor = localStorage.getItem("uniqueVisitor");
    const countRef = ref(database, "visitorCount/count");

    const incrementVisitorCount = async () => {
      try {
        const snapshot = await get(countRef);
        const currentCount = snapshot.exists() ? snapshot.val() : 0;
        const newCount = currentCount + 1;

        await set(countRef, newCount);
        setVisitorCount(newCount);
        localStorage.setItem("uniqueVisitor", "true");

        console.log("Visitor count incremented:", newCount);
      } catch (error) {
        console.error("Error updating visitor count:", error);
      }
    };

    const fetchVisitorCount = () => {
      onValue(countRef, (snapshot) => {
        if (snapshot.exists()) {
          const currentCount = snapshot.val();
          setVisitorCount(currentCount);
          console.log("Fetched visitor count:", currentCount);
        }
      });
    };

    if (!isVisitor) {
      incrementVisitorCount();
    } else {
      fetchVisitorCount();
    }
  }, []);

  return (
    <div className="App">
      <div className="content">
        <img src="../wr50.jpg" alt="Centered" className="centered-image" />
        <h3>
          शिक्षक भर्ती लेवल 1 में 50 प्रतिशत महिला आरक्षण में पुनर्विचार करने के
          लिए
        </h3>
        <p className="description"></p>
        <p className="letter-body">
          मान्यवर जी, उपरोक्त विषय में निवेदन है कि माननीय मुख्यमंत्री जी ने
          शिक्षक भर्ती में महिला आरक्षण 30 प्रतिशत से बढ़ाकर 50 प्रतिशत करने का
          फैसला लिया है...
        </p>
        <p className="recipients">
          <strong>To:</strong> {recipient}
          <br />
          <strong>CC:</strong> {displayedCCRecipients.join(", ")}
        </p>
        <button onClick={handleSendEmail} className="send-button">
          Send Mail Now
        </button>
        <p className="visitor-counter">
          <b></b> {visitorCount}
        </p>
      </div>
    </div>
  );
}

export default App;
