// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNPaBpBnG4Rsq3qDPQrYagHyNPqSgA3MU",
  authDomain: "iadv-ad525.firebaseapp.com",
  projectId: "iadv-ad525",
  storageBucket: "iadv-ad525.appspot.com",
  messagingSenderId: "583091784689",
  appId: "1:583091784689:web:309b5859db83da10765250",
  measurementId: "G-ME569X3Q7W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

// Export the database
export { database };
